import { HttpError } from '@refinedev/core';
import axios from 'axios';

import { TOKEN_KEY } from './authProvider';

//TODO добавить baseurl и обновить все запросы
const axiosInstance = axios.create({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('refine-auth')}`,
  },
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  },
);

export { axiosInstance };
