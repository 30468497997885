import { DateField, Show, TextField } from '@refinedev/antd';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import { Typography } from 'antd';
import { type FC } from 'react';

const { Title } = Typography;

const ClienteleShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  return (
    <Show
      isLoading={isLoading}
      title="Показать клиента"
    >
      <Title level={5}>Имя</Title>
      <TextField value={data?.data.firstname} />

      <Title level={5}>Дата рождения</Title>
      <DateField value={data?.data.birthday} />

      <Title level={5}>Номер телефона</Title>
      <TextField value={data?.data.phone} />

      <Title level={5}>Email</Title>
      <TextField value={data?.data.email} />

      {/* <Title level={5}>История поездок</Title>
            <TextField value={data?.data.email} /> */}
    </Show>
  );
};

export default ClienteleShow;
