import { Col, DatePicker, Form, Input, Row } from 'antd';
import dayjs from 'dayjs';
import { type FC } from 'react';
import { emailValidation, phoneValidation } from 'utils/validation/validation';

const CapitanForm: FC<any> = ({ ...props }) => {
  return (
    <Form {...props}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Фамилия"
            name="lastname"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input autoFocus={true} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Имя"
            name="firstname"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Отчество"
            name="midname"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Номер телефона"
            name="phone"
            rules={[
              {
                required: true,
                ...phoneValidation,
              },
            ]}
          >
            <Input placeholder="79*********" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Дата рождения"
            name="birthday"
            getValueProps={value => ({
              value: value ? dayjs(value) : '',
            })}
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                ...emailValidation,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CapitanForm;
