import { List, ShowButton, useTable } from '@refinedev/antd';
import { type BaseRecord, type IResourceComponentsProps } from '@refinedev/core';
import { Space, Table, Tag } from 'antd';
import { ticketTag } from 'components/ticketTag';
import { TicketI } from 'model/ticket/interface';
import { type FC } from 'react';

const TicketList: FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<TicketI>({
    resource: 'support/ticket',
  });

  return (
    <List>
      <Table {...tableProps}>
        <Table.Column
          dataIndex="active"
          title="Статус"
          sorter
          render={(_, record: TicketI) => (
            <Tag color={record?.active ? ticketTag.open.color : ticketTag.closed.color}>
              {record?.active ? ticketTag.open.text : ticketTag.closed.text}
            </Tag>
          )}
        />
        <Table.Column
          dataIndex="id"
          title="Номер обращения"
          sorter
        />
        <Table.Column
          dataIndex={['SupportTopic', 'name']}
          title="Проблема"
        />

        <Table.Column
          title="Действия"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default TicketList;
