import { useList, useSelect } from '@refinedev/core';
import { Checkbox, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { UploadForm } from 'components';
import { type FC } from 'react';

const BoatForm: FC<any> = ({
  files,
  mainImage,
  onFinish,
  isVideoFieldsValidating,
  videos,
  videos_preview,
  ...props
}) => {
  const boatOwnerOptions = useSelect({
    resource: 'boat-owner',
    optionLabel: 'name',
    optionValue: 'id',
  });

  const boatCategoriesOptions = useSelect({
    resource: 'boat-categories',
    optionLabel: 'name',
    optionValue: 'id',
  });

  const captainOptions = useList({
    resource: 'captains',
    meta: {
      take: 999,
    },
    pagination: {
      mode: 'off',
    },
  });

  const pierOptions = useSelect({
    resource: 'piers',
    optionLabel: 'name',
    optionValue: 'id',
  });

  const serviceOptions = useSelect({
    resource: 'services',
    optionLabel: 'name',
    optionValue: 'id',
  });

  return (
    <Form
      {...props}
      onFinish={data => {
        console.log(data);
        const preparedData = {
          ...data,
          images: data?.images?.fileList?.map((el: any) => el.response[0]),
          main_image: data?.main_image?.fileList?.map((el: any) => el.response[0]),
          videos: data?.video && [
            {
              file: data?.video?.fileList?.[0]?.response?.[0],
              preview_image: data?.video_preview?.fileList?.[0]?.response?.[0],
            },
          ],
        };
        delete preparedData['video_preview'];
        delete preparedData['video'];
        onFinish(preparedData);
      }}
    >
      <Form.Item
        label="Активность"
        valuePropName="checked"
        initialValue={true}
        name={['is_active']}
        rules={[
          {
            required: true,
            message: 'Обязательное поле',
          },
        ]}
      >
        <Checkbox></Checkbox>
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Название"
            name="name"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Марка"
            name="brand"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Модель"
            name="model"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Номер судна"
            name="reg_name"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Категория"
            name="category_id"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Select {...boatCategoriesOptions} />
          </Form.Item>
          <Form.Item
            label="Собственник"
            name="owner_id"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Select {...boatOwnerOptions} />
          </Form.Item>
          <Form.Item
            label="Капитан"
            name="captain_id"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Select
              options={captainOptions.data?.data?.map(el => ({
                label: `${el.lastname} ${el.firstname}`,
                value: el.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Услуги"
            name="services_id"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Select
              mode="multiple"
              {...serviceOptions}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Количество пассажиров"
            name="passengers_count"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label="Высота борта"
            name="side_height"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label="Максимальная скорость"
            name="max_speed"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label="Базовая стоимость за час"
            name="price_per_hour"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label="Базовый пирс"
            name="base_pier_id"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Select {...pierOptions} />
          </Form.Item>
          <Form.Item
            label="Описание характеристик"
            name="description"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Краткое описание"
            name="short_description"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="ID Google календаря"
            name="google_cal_id"
          >
            <Input />
          </Form.Item>
        </Col>
        <Row>
          <Col>
            <Form.Item
              label="Изображения"
              name="images"
            >
              <UploadForm defaultFileList={files} />
            </Form.Item>
            <Form.Item
              label="Основное изображение"
              name="main_image"
            >
              <UploadForm
                maxCount={1}
                defaultFileList={mainImage}
              />
            </Form.Item>
            <Row>
              <Form.Item
                label="Видео"
                name="video"
                rules={[
                  isVideoFieldsValidating && {
                    required: true,
                    message: 'Обязательное поле',
                  },
                ]}
              >
                <UploadForm
                  maxCount={1}
                  defaultFileList={videos}
                />
              </Form.Item>
              <Form.Item
                label="Превью"
                name="video_preview"
                rules={[
                  isVideoFieldsValidating && {
                    required: true,
                    message: 'Обязательное поле',
                  },
                ]}
              >
                <UploadForm
                  maxCount={1}
                  defaultFileList={videos_preview && [videos_preview]}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Row>
    </Form>
  );
};

export default BoatForm;
