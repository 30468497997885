import { Create, useForm } from '@refinedev/antd';
import { IResourceComponentsProps } from '@refinedev/core';
import ClientForm from 'forms/ClientForm';
import { type ClientI } from 'model/client/interface';
import { type FC } from 'react';

const ClientCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ClientI>();

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Создать клиента"
    >
      <ClientForm {...formProps} />
    </Create>
  );
};

export default ClientCreate;
