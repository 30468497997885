import { useForm } from '@refinedev/core';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { UploadForm } from 'components';
import { type FC } from 'react';

const ServiceForm: FC<any> = ({ files, ...props }) => {
  const { onFinish } = useForm();

  return (
    <Form
      {...props}
      onFinish={data => onFinish({ ...data, icon: data?.icon?.file?.response[0] })}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Название"
            name="name"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Активность"
            valuePropName="checked"
            initialValue={true}
            name={['is_active']}
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Checkbox></Checkbox>
          </Form.Item>
          <Form.Item
            label="Иконка (только svg)"
            name="icon"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <UploadForm
              maxCount={1}
              defaultFileList={files}
              accept="image/svg+xml"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ServiceForm;
