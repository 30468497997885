import { Image as AtndImage } from 'antd';
import type { ImageProps } from 'antd/es/image';
import { type FC } from 'react';

import './index.css';

interface ImageI extends ImageProps {
  fileId: string;
}

export const Image: FC<ImageI> = ({ fileId, ...props }) => {
  return (
    <AtndImage
      className="custom-image"
      src={`${process.env.REACT_APP_API_URL}/file/${fileId}`}
      {...props}
    />
  );
};
