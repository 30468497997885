import { Create, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import { useWatch } from 'antd/es/form/Form';
import BoatForm from 'forms/BoatForm';
import { BoatI } from 'model/boat/interface';
import { type FC } from 'react';

const BoatsCreate: FC<IResourceComponentsProps> = () => {
  const form = useForm<BoatI>();

  const isPreviewValueAdded = useWatch('video_preview', form);
  const isVideoValueAdded = useWatch('video', form);

  const isVideoFieldsValidating = isPreviewValueAdded?.fileList?.length || isVideoValueAdded?.fileList?.length;

  const { formProps, saveButtonProps } = form;

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Создание лодки"
    >
      <BoatForm
        {...formProps}
        isVideoFieldsValidating={isVideoFieldsValidating}
      />
    </Create>
  );
};

export default BoatsCreate;