import { useLogin } from '@refinedev/core';
import { Layout as AntdLayout, Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import React from 'react';

import './styles.css';

const { Title } = Typography;

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const Login: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>();

  const { mutate: login } = useLogin<ILoginForm>();

  const CardTitle = (
    <Title
      level={3}
      className="title"
    >
      Вход
    </Title>
  );

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div className="container">
            <Card
              title={CardTitle}
              headStyle={{ borderBottom: 0 }}
            >
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={values => {
                  login(values);
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="email"
                  label="Почта"
                  rules={[{ required: true }]}
                >
                  <Input
                    size="large"
                    placeholder="Почта"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Пароль"
                  rules={[{ required: true }]}
                  style={{ marginBottom: '12px' }}
                >
                  <Input
                    type="password"
                    placeholder="●●●●●●●●"
                    size="large"
                  />
                </Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                >
                  Войти
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
