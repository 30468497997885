import { BooleanField, Show, TextField } from '@refinedev/antd';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import { Typography } from 'antd';
import { Image } from 'components';

const { Title } = Typography;

export const CategoryShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const category = queryResult?.data?.data;

  return (
    <Show
      isLoading={queryResult?.isLoading}
      title="Просмотр категории"
    >
      <Title level={5}>Id</Title>
      <TextField value={category?.id} />

      <Title level={5}>Родительский ID</Title>
      <TextField value={category?.parent_id || '-'} />

      <Title level={5}>Код</Title>
      <TextField value={category?.code} />

      <Title level={5}>Наименование</Title>
      <TextField value={category?.name} />

      <Title level={5}>Активность</Title>
      <BooleanField value={category?.is_active} />

      <Title level={5}>Фото</Title>
      <Image fileId={category?.icon[0].id} />
    </Show>
  );
};
