import { Col, Form, Input, Row } from 'antd';
import { type FC } from 'react';
import { phoneValidation } from 'utils/validation';

const BoatOwnerForm: FC<any> = ({ ...props }) => {
  return (
    <Form {...props}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Имя"
            name="firstname"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Фамилия"
            name="lastname"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Телефон"
            name="phone"
            rules={[
              {
                required: true,
                ...phoneValidation,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BoatOwnerForm;
