import { DateField, List, ShowButton, TextField, useTable } from '@refinedev/antd';
import { type BaseRecord, type IResourceComponentsProps } from '@refinedev/core';
import { Space, Table } from 'antd';
import { type BookingI } from 'model/booking/interface';
import { bookingPayStatus } from 'utils/const';

const BookingsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<BookingI>({
    sorters: {
      initial: [
        {
          field: 'created_at',
          order: 'desc',
        },
      ],
    },
  });

  return (
    <List>
      <Table {...tableProps}>
        <Table.Column
          dataIndex="id"
          title="Номер заказа"
          width="3%"
          render={(_, record: BaseRecord) => <TextField value={record?.id} />}
        />
        <Table.Column
          dataIndex="start_time"
          title="Дата"
          render={(_, record: BaseRecord) => (
            <DateField
              value={record.start_time}
              format="DD.MM.YYYY"
            />
          )}
        />
        <Table.Column
          dataIndex="time"
          title="Время"
          render={(_, record: BaseRecord) => (
            <DateField
              value={record.start_time}
              format="HH:mm"
            />
          )}
        />
        <Table.Column
          dataIndex="brand"
          title="Марка"
          render={(_, record: BaseRecord) => <TextField value={record?.boat?.brand} />}
        />
        <Table.Column
          dataIndex="model"
          title="Модель"
          render={(_, record: BaseRecord) => <TextField value={record?.boat?.model} />}
        />
        <Table.Column
          dataIndex="catpain_name"
          title="ФИО капитана"
          render={(_, record: BaseRecord) => (
            <TextField
              value={` ${record?.captain?.lastname} ${record?.captain?.firstname} ${record?.captain?.midname || ''}`}
            />
          )}
        />
        <Table.Column
          dataIndex="route"
          title="Маршрут"
          render={(_, record: BaseRecord) => <TextField value={record?.route?.name} />}
        />
        <Table.Column
          dataIndex="client_name"
          title="Имя клиента"
          render={(_, record: BaseRecord) => (
            <TextField value={`${record?.user?.lastname} ${record?.user?.firstname}`} />
          )}
        />
        <Table.Column
          dataIndex="status"
          title="Статус бронирования"
          render={(_, record: BookingI) => <TextField value={bookingPayStatus.getOne(record.status)} />}
        />
        <Table.Column
          dataIndex="start_pier"
          title="Причал посадки"
          render={(_, record: BaseRecord) => <TextField value={record?.start_pier?.name} />}
        />
        <Table.Column
          dataIndex="end_pier"
          title="Причал высадки"
          render={(_, record: BaseRecord) => <TextField value={record?.end_pier?.name} />}
        />
        <Table.Column
          title="Действия"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default BookingsList;

// Дата, Время, Марка и модель лодки, ФИО капитана, маршрут, Фамилия и имя клиента, статус оплаты ,причал посадки, причал высадки
