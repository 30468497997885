import type { Dispatch, SetStateAction } from 'react';

export interface IQueryField {
  filters: [];
  setStartDate: Dispatch<SetStateAction<string>>;
  setEndDate: Dispatch<SetStateAction<string>>;
  setCapitan: Dispatch<SetStateAction<number>>;
  setBoat: Dispatch<SetStateAction<number>>;
}

export enum EQueryField {
  BOAT = 'Лодка',
  CAPTAIN = 'Капитан',
  END_DATE = 'Дата до',
  START_DATE = 'Дата от',
}
