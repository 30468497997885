import { Show, TextField } from '@refinedev/antd';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import { Typography } from 'antd';

const { Title } = Typography;

export const BoatOwnerShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  return (
    <Show
      isLoading={isLoading}
      title="Просмотр собственника"
    >
      <Title level={5}>Id</Title>
      <TextField value={data?.data.id} />

      <Title level={5}>Фамилия</Title>
      <TextField value={data?.data.lastname || '-'} />

      <Title level={5}>Имя</Title>
      <TextField value={data?.data?.firstname} />

      <Title level={5}>Телефон</Title>
      <TextField value={data?.data?.phone || '-'} />
    </Show>
  );
};
