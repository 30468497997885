interface Query {
  startDate: string;
  endDate: string;
  boat: number;
  capitan: number;
}

export const queryParse = (query: Query) => {
  let querySearch = '?';

  if (query.startDate) {
    querySearch += `START_DATE=${query.startDate}&`;
  }
  if (query.endDate) {
    querySearch += `END_DATE=${query.endDate}&`;
  }
  if (query.boat) {
    querySearch += `BOAT=${query.boat}&`;
  }
  if (query.capitan) {
    querySearch += `CAPTAIN=${query.capitan}&`;
  }

  return querySearch;
};
