import { DateField, ListButton, RefreshButton, Show, TextField, UrlField } from '@refinedev/antd';
import { type IResourceComponentsProps, useApiUrl, useShow, useUpdate } from '@refinedev/core';
import { Button, Input, Row, Space, Typography } from 'antd';
import { axiosInstance } from 'api/axiosInstance';
import { CancelButton } from 'components';
import { type BookingI, BookingStatusT } from 'model/booking/interface';
import { type ChangeEvent, type FC, useState } from 'react';
import { bookingPayStatus } from 'utils/const';

const { Title } = Typography;

const BookingShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<BookingI>();
  const { mutate } = useUpdate<BookingI>();
  const [bookingLabel, setBookingLabel] = useState<string>();

  const apiUrl = useApiUrl();

  const booking = queryResult.data?.data;

  const handleSubmit = () => {
    if (booking?.id) {
      mutate({
        id: booking.id,
        resource: 'bookings',
        values: {
          comment: bookingLabel,
        },
      });
    }
  };

  const handleChangeBookingLabel = (e: ChangeEvent<HTMLInputElement>) => setBookingLabel(e.target.value);

  const handleDeleteReview = async () => {
    await axiosInstance.get(`${apiUrl}/bookings/${booking?.id}/review/delete`);
    queryResult.refetch();
  };

  return (
    <Show
      title="Просмотр бронирования"
      headerButtons={() => (
        <>
          <ListButton />
          <CancelButton
            resource="bookings"
            itemId={booking?.id}
          />
          <RefreshButton />
        </>
      )}
    >
      <Row justify="space-between">
        <div>
          <Title level={5}>Дата и время</Title>
          <DateField
            value={booking?.start_time}
            format="DD.MM.YYYY HH:mm"
          />

          <Title level={5}>Марка</Title>
          <TextField value={booking?.boat.brand} />

          <Title level={5}>Модель</Title>
          <TextField value={booking?.boat.model} />

          <Title level={5}>ФИО капитана</Title>
          <TextField value={`${booking?.captain.lastname} ${booking?.captain.firstname} ${booking?.captain.midname}`} />

          <Title level={5}>Имя клиента</Title>
          <TextField value={`${booking?.user.lastname} ${booking?.user.firstname}`} />

          <Title level={5}>Телефон</Title>
          <TextField value={booking?.user.phone} />

          <Title level={5}>Почта</Title>
          <TextField value={booking?.user.email} />

          <Title level={5}>Статус оплаты</Title>
          <TextField value={booking?.status && bookingPayStatus.getOne(booking?.status)} />
          {booking?.status === 'PREPAID' && [
            <Title key="title" level={5}>Сумма предоплаты</Title>,
            <TextField value={booking?.paid + ' ₽'} />,
            <Title key="title" level={5}>Остаток</Title>,
            <TextField value={(booking?.full_price - booking?.paid) + ' ₽'} />
          ]}
          {booking?.status === 'PAID' && [
            <Title key="title" level={5}>Стоимость</Title>,
            <TextField value={booking?.full_price + ' ₽'} />,
          ]}
          <Title level={5}>Маршрут</Title>
          <UrlField value={`/routes/show/${booking?.route.id}`}>{booking?.route.name}</UrlField>
        </div>
        <Space
          direction="vertical"
          align="end"
          size={50}
        >
          <Space
            direction="vertical"
            align="end"
          >
            <Title level={5}>Пометки</Title>
            {!queryResult.isLoading && (
              <Input
                defaultValue={booking?.comment}
                onChange={handleChangeBookingLabel}
                style={{ width: '400px' }}
              />
            )}
            <Button onClick={handleSubmit}>Добавить</Button>
          </Space>
          {booking?.review && (
            <Space
              direction="vertical"
              align="end"
            >
              <Title level={5}>Отзыв</Title>
              {!queryResult.isLoading && (
                <TextField
                  value={booking?.review?.text}
                  style={{ width: '400px' }}
                />
              )}
              <Button
                danger
                onClick={handleDeleteReview}
              >
                Удалить
              </Button>
            </Space>
          )}
        </Space>
      </Row>
    </Show>
  );
};

export default BookingShow;

// Дата, Время, Марка и модель лодки, ФИО капитана, маршрут, Фамилия и имя клиента, статус оплаты ,причал посадки, причал высадки
