import { message } from 'antd';
import { closeTicket } from 'utils/tickets/closeTicket';

export const closeTicketHandler = async (id: string | undefined, refetch: () => void) => {
  if (!id) return;
  try {
    await closeTicket(id);
    message.success('Тикет закрыт');
    refetch();
  } catch (error) {
    message.error('Ошибка при закрытии тикета');
  }
};
