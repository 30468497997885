import { useList } from '@refinedev/core';
import { DatePicker, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import { type FC, Fragment, memo } from 'react';

import { EQueryField, IQueryField } from './interface';

const { Title } = Typography;

const QueryField: FC<IQueryField> = props => {
  const { filters, setBoat, setCapitan, setEndDate, setStartDate } = props;

  const { data: captains } = useList({ resource: 'captains' });
  const { data: boats } = useList({ resource: 'boats' });

  const captain = captains?.data?.map(captain => ({
    label: `${captain.firstname} ${captain.lastname}`,
    value: captain.id,
  }));
  const boat = boats?.data?.map(boat => ({ label: boat.name, value: boat.id }));

  const currentComponent = {
    [EQueryField.START_DATE]: <DatePicker onChange={value => setStartDate(dayjs(value).format('YYYY-MM-DD'))} />,
    [EQueryField.END_DATE]: <DatePicker onChange={value => setEndDate(dayjs(value).format('YYYY-MM-DD'))} />,
    [EQueryField.CAPTAIN]: (
      <Select
        dropdownMatchSelectWidth={false}
        options={captain}
        onChange={value => setCapitan(value)}
        placeholder="Капитан"
      />
    ),
    [EQueryField.BOAT]: (
      <Select
        dropdownMatchSelectWidth={false}
        options={boat}
        onChange={value => setBoat(value)}
        placeholder="Лодка"
      />
    ),
  };

  return (
    <>
      {filters?.map((filter, index) => (
        <Fragment key={index}>
          <Title level={5}>{EQueryField[filter]}</Title>
          {currentComponent[EQueryField[filter]]}
        </Fragment>
      ))}
    </>
  );
};

export default memo(QueryField);
