import { Edit, useForm } from '@refinedev/antd';
import ActionForm from 'forms/ActionForm';
import { ActionI } from 'model/actions/interface';
import { formatter } from 'utils/formatter';

const ActionEdit = () => {
  const form = useForm<ActionI>();

  const { formProps, saveButtonProps, formLoading, queryResult, onFinish } = form;

  const oldPrice = Number(queryResult?.data?.data?.boats[0].price_per_hour);

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title="Редактирование акции"
    >
      <div style={{ display: 'flex', gap: '12px', marginBottom: '12px' }}>
        <p>Название лодки:</p>
        <strong>{queryResult?.data?.data?.boats[0].name}</strong>
      </div>
      <div style={{ display: 'flex', gap: '12px', marginBottom: '12px' }}>
        <p>Категория лодки:</p>
        <strong>{queryResult?.data?.data?.boats[0].category.name}</strong>
      </div>
      <div style={{ display: 'flex', gap: '12px', marginBottom: '12px' }}>
        <p>Старая цена:</p>
        <strong>{formatter.format(oldPrice)}</strong>
      </div>
      <ActionForm
        {...formProps}
        onFinish={onFinish}
      />
    </Edit>
  );
};
export default ActionEdit;