import { Edit, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import AdminForm from 'forms/AdminForm';
import { type FC } from 'react';

const AdminsEdit: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm();

  return (
    <Edit
      isLoading={formLoading}
      title="Редактирование администратора"
      saveButtonProps={saveButtonProps}
    >
      <AdminForm {...formProps} />
    </Edit>
  );
};

export default AdminsEdit;
