import { DateField, DeleteButton, EditButton, List, ShowButton, useTable } from '@refinedev/antd';
import { BaseRecord, IResourceComponentsProps } from '@refinedev/core';
import { Space, Table } from 'antd';
import { type ClientI } from 'model/client/interface';
import { type FC } from 'react';

const ClienteleList: FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<ClientI[]>();

  return (
    <List>
      <Table {...tableProps}>
        <Table.Column
          dataIndex="firstname"
          title="Имя"
          sorter
        />
        <Table.Column
          dataIndex="birthday"
          title="Дата рождения"
          render={value => <DateField value={value} />}
        />
        <Table.Column
          dataIndex="phone"
          title="Номер телефона"
          sorter
        />
        <Table.Column
          dataIndex="email"
          title="Email"
        />
        <Table.Column
          title="Действия"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default ClienteleList;
