import { YMaps } from '@pbe/react-yandex-maps';
import { ErrorComponent, ThemedLayout, notificationProvider } from '@refinedev/antd';
import '@refinedev/antd/dist/reset.css';
import { Authenticated, Refine } from '@refinedev/core';
import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar';
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';
import { dataProvider } from 'api/dataProvider/dataProvider';
import HeaderLogo from 'components/headerLogo';
import { useContentPermissions } from 'hooks/useContentPermissions/useContentPermissions';
import { AdminCreate, AdminEdit, AdminsList } from 'pages/admins';
import { BoatOwnerCreate, BoatOwnerEdit, BoatOwnerList, BoatOwnerShow } from 'pages/boat_owner';
import { BoatsCreate, BoatsEdit, BoatsList, BoatsShow } from 'pages/boats';
import { BookingCreate, BookingShow, BookingsList } from 'pages/bookings';
import { CapitanEdit, CapitanShow, CapitansList, CapitarCreate } from 'pages/captain';
import { CategoryCreate, CategoryEdit, CategoryList, CategoryShow } from 'pages/categories';
import { ClienteleCreate, ClienteleEdit, ClienteleList, ClienteleShow } from 'pages/clientele';
import { ForgotPassword } from 'pages/forgotPassword';
import { Login } from 'pages/login';
import { PierEdit, PierShow, PiersList } from 'pages/piers';
import PushCreate from 'pages/push/PushCreate';
import { Register } from 'pages/register';
import { ReportCreate, ReportList, ReportShow } from 'pages/reports';
import { RouteEdit, RouteShow, RoutesList } from 'pages/routes';
import { ServiceCreate, ServiceEdit, ServicesList } from 'pages/services';
import TicketList from 'pages/tickets/list';
import TicketShow from 'pages/tickets/show';
import TopicsCreate from 'pages/topics/create';
import TopicsList from 'pages/topics/list';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import ActionCreate from 'pages/actions/create';
import ActionEdit from 'pages/actions/edit';
import ActionsList from 'pages/actions/list';
import { authProvider } from './api/authProvider';
import { Header } from './components/header';
import { ColorModeContextProvider } from './contexts/color-mode';

function App() {
  const { t, i18n } = useTranslation();
  const { permissions } = useContentPermissions();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <YMaps>
          <ColorModeContextProvider>
            <Refine
              dataProvider={dataProvider(`${process.env.REACT_APP_API_URL}/admin`)}
              // dataProvider={dataProvider("https://api.fake-rest.refine.dev")}
              notificationProvider={notificationProvider}
              routerProvider={routerBindings}
              authProvider={authProvider}
              i18nProvider={i18nProvider}
              Title={HeaderLogo}
              resources={permissions}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
              }}
            >
              <Routes>
                <Route
                  element={
                    <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                      <ThemedLayout Header={Header}>
                        <Outlet />
                      </ThemedLayout>
                    </Authenticated>
                  }
                >
                  <Route path="/boats">
                    <Route
                      index
                      element={<BoatsList />}
                    />
                    <Route
                      path="create"
                      element={<BoatsCreate />}
                    />
                    <Route
                      path="show/:id"
                      element={<BoatsShow />}
                    />
                    <Route
                      path="edit/:id"
                      element={<BoatsEdit />}
                    />
                  </Route>
                  <Route path="/bookings">
                    <Route
                      index
                      element={<BookingsList />}
                    />
                    <Route
                      path="show/:id"
                      element={<BookingShow />}
                    />
                    <Route
                      path="create"
                      element={<BookingCreate />}
                    />
                  </Route>
                  <Route path="/users">
                    <Route
                      index
                      element={<ClienteleList />}
                    />
                    <Route
                      path="edit/:id"
                      element={<ClienteleEdit />}
                    />
                    <Route
                      path="create"
                      element={<ClienteleCreate />}
                    />
                    <Route
                      path="show/:id"
                      element={<ClienteleShow />}
                    />
                  </Route>
                  <Route path="/admins">
                    <Route
                      index
                      element={<AdminsList />}
                    />
                    <Route
                      path="edit/:id"
                      element={<AdminEdit />}
                    />
                    <Route
                      path="create"
                      element={<AdminCreate />}
                    />
                  </Route>
                  <Route path="/captains">
                    <Route
                      index
                      element={<CapitansList />}
                    />
                    <Route
                      path="create"
                      element={<CapitarCreate />}
                    />
                    <Route
                      path="edit/:id"
                      element={<CapitanEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<CapitanShow />}
                    />
                  </Route>
                  {/* <Route path="/trip">
                    <Route index element={<TripInitiation />} />
                  </Route> */}
                  <Route path="/piers">
                    <Route
                      index
                      element={<PiersList />}
                    />
                    <Route
                      path="edit/:id"
                      element={<PierEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<PierShow />}
                    />
                  </Route>
                  <Route path="/routes">
                    <Route
                      index
                      element={<RoutesList />}
                    />
                    <Route
                      path="edit/:id"
                      element={<RouteEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<RouteShow />}
                    />
                  </Route>
                  <Route path="/boat-categories">
                    <Route
                      index
                      element={<CategoryList />}
                    />
                    <Route
                      path="create"
                      element={<CategoryCreate />}
                    />
                    <Route
                      path="edit/:id"
                      element={<CategoryEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<CategoryShow />}
                    />
                  </Route>
                  <Route path="/services">
                    <Route
                      index
                      element={<ServicesList />}
                    />
                    <Route
                      path="create"
                      element={<ServiceCreate />}
                    />
                    <Route
                      path="edit/:id"
                      element={<ServiceEdit />}
                    />
                  </Route>
                  <Route path="/boat-owner">
                    <Route
                      index
                      element={<BoatOwnerList />}
                    />
                    <Route
                      path="create"
                      element={<BoatOwnerCreate />}
                    />
                    <Route
                      path="edit/:id"
                      element={<BoatOwnerEdit />}
                    />
                    <Route
                      path="show/:id"
                      element={<BoatOwnerShow />}
                    />
                  </Route>
                  <Route path="/actions">
                    <Route
                      index
                      element={<ActionsList />}
                    />
                    <Route
                      path="create"
                      element={<ActionCreate />}
                    />
                    <Route
                      path="edit/:id"
                      element={<ActionEdit />}
                    />

                  </Route>
                  <Route path="/reports">
                    <Route
                      index
                      element={<ReportList />}
                    />
                    <Route
                      path="show/:id"
                      element={<ReportShow />}
                    />
                    <Route
                      path="create"
                      element={<ReportCreate />}
                    />
                    {/* <Route path="edit/:id" element={<BoatOwnerEdit />} /> */}
                  </Route>
                  <Route path="/push/create">
                    <Route
                      index
                      element={<PushCreate />}
                    />
                  </Route>
                  <Route path="/topic">
                    <Route
                      index
                      element={<TopicsList />}
                    />
                    <Route
                      path="create"
                      element={<TopicsCreate />}
                    />
                  </Route>
                  <Route path="/ticket">
                    <Route
                      index
                      element={<TicketList />}
                    />
                    <Route
                      path="show/:id"
                      element={<TicketShow />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <Authenticated fallback={<Outlet />}>
                      <NavigateToResource />
                    </Authenticated>
                  }
                >
                  <Route
                    path="/login"
                    element={<Login />}
                  />
                  <Route
                    path="/register"
                    element={<Register />}
                  />
                  <Route
                    path="/forgot-password"
                    element={<ForgotPassword />}
                  />
                </Route>
                <Route
                  element={
                    <Authenticated>
                      <ThemedLayout Header={Header}>
                        <Outlet />
                      </ThemedLayout>
                    </Authenticated>
                  }
                >
                  <Route
                    path="*"
                    element={<ErrorComponent />}
                  />
                </Route>
              </Routes>

              <RefineKbar />
              <UnsavedChangesNotifier />
            </Refine>
          </ColorModeContextProvider>
        </YMaps>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
