import { HttpError, useForm, useList } from '@refinedev/core';
import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { UploadForm } from 'components';
import { CategoryI } from 'model/category/interface';
import { type FC } from 'react';

const CategoryForm: FC<any> = ({ files, ...props }) => {
  const { data, isLoading } = useList<CategoryI, HttpError>({
    resource: 'boat-categories',
  });

  const { onFinish } = useForm();

  const options = data?.data?.map(category => ({ label: category.name, value: category.id }));

  return (
    <Form
      {...props}
      onFinish={data => onFinish({ ...data, icon: data?.icon?.file?.response[0] })}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Код"
            name="code"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input autoFocus={true} />
          </Form.Item>
          <Form.Item
            label="Название"
            name="name"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Родитель"
            name="parent_id"
          >
            <Select
              loading={isLoading}
              options={options}
            />
          </Form.Item>
          <Form.Item
            label="Активность"
            valuePropName="checked"
            initialValue={true}
            name={['is_active']}
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <Checkbox></Checkbox>
          </Form.Item>
          <Form.Item
            label="Иконка (только svg)"
            name="icon"
            rules={[
              {
                required: true,
                message: 'Обязательное поле',
              },
            ]}
          >
            <UploadForm
              defaultFileList={files}
              maxCount={1}
              accept="image/svg+xml"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CategoryForm;
