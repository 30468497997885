import { DateField, Show, TextField } from '@refinedev/antd';
import { type IResourceComponentsProps, useShow } from '@refinedev/core';
import { Typography } from 'antd';
import { CaptainI } from 'model/captain/interface';
import { type FC } from 'react';

const { Title } = Typography;

const CapitanShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<CaptainI>();
  const { data, isLoading } = queryResult;

  return (
    <Show
      isLoading={isLoading}
      title="Просмотр капитана"
    >
      <Title level={5}>Фамилия</Title>
      <TextField value={data?.data.lastname} />

      <Title level={5}>Имя</Title>
      <TextField value={data?.data.firstname} />

      <Title level={5}>Отчество</Title>
      <TextField value={data?.data.midname} />

      <Title level={5}>Дата рождения</Title>
      <DateField value={3} />

      <Title level={5}>Номер телефона</Title>
      <TextField value={data?.data.phone} />

      <Title level={5}>Email</Title>
      <TextField value={data?.data.email} />
    </Show>
  );
};

export default CapitanShow;
