import { DateField, DeleteButton, EditButton, List, useTable } from '@refinedev/antd';
import { type BaseRecord, type IResourceComponentsProps } from '@refinedev/core';
import { Space, Table } from 'antd';
import { AdminI } from 'model/admin/interface';
import { type FC } from 'react';

const AdminsList: FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<AdminI[]>();

  return (
    <List>
      <Table {...tableProps}>
        <Table.Column
          dataIndex="lastname"
          title="Фамилия"
          sorter
        />
        <Table.Column
          dataIndex="firstname"
          title="Имя"
          sorter
        />
        <Table.Column
          dataIndex="midname"
          title="Отчество"
        />
        <Table.Column
          dataIndex="birthday"
          title="Дата рождения"
          sorter
          render={value => <DateField value={value} />}
        />
        <Table.Column
          dataIndex="phone"
          title="Номер телефона"
          sorter
        />
        <Table.Column
          dataIndex="email"
          title="Email"
          sorter
        />
        <Table.Column
          title="Действия"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default AdminsList;
