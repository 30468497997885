import { Edit, useForm } from '@refinedev/antd';
import { IResourceComponentsProps } from '@refinedev/core';
import ServiceForm from 'forms/ServiceForm';

const ServiceEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading, queryResult } = useForm();

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title="Редактирование услуги"
    >
      <ServiceForm
        files={queryResult?.data?.data.icon}
        {...formProps}
      />
    </Edit>
  );
};

export default ServiceEdit;
