import { Typography } from 'antd';
import { type FC } from 'react';

const HeaderLogo: FC = () => {
  return (
    <Typography.Title
      level={4}
      style={{ margin: 0 }}
    >
      Smart Boats
    </Typography.Title>
  );
};

export default HeaderLogo;
