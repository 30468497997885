import { message } from 'antd';
import { SupportMessageI } from 'model/ticket/interface';
import { Dispatch, SetStateAction } from 'react';
import { photoMessageI, sendMessageImage, sendMessageText, textMessageI } from 'utils/tickets/sendMessage';

export const sendMessageHandler = async (
  id: string | undefined,
  inputMessage: string,
  imageUUID: string,
  setInputMessage: (message: string) => void,
  setImageUUID: (uuid: string) => void,
  setMessages: Dispatch<SetStateAction<SupportMessageI[]>>,
) => {
  if (!id) return;

  try {
    if (imageUUID) {
      const myMessage: photoMessageI = {
        type: 'PHOTO',
        support_ticket_id: Number(id),
        files: [imageUUID],
      };

      const response = await sendMessageImage(myMessage);
      setMessages(prev => [...prev, response]);
      message.success('Сообщение отправлено');
      setImageUUID(''); // Обновляем состояние imageUUID
    } else if (inputMessage) {
      const myMessage: textMessageI = {
        type: 'TEXT',
        support_ticket_id: Number(id),
        content: inputMessage,
      };
      const response = await sendMessageText(myMessage);
      setMessages(prev => [...prev, response]);
      message.success('Сообщение отправлено');
      setInputMessage(''); // Обновляем состояние inputMessage
    }
  } catch (error) {
    message.error('Ошибка при отправке сообщения');
  }
};
