import { useSelect } from '@refinedev/antd';
import { DatePicker, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FC } from 'react';

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const dateFormat = 'DD-MM-YYYY';

const ActionForm: FC<any> = ({ onFinish, addBoar, ...props }) => {
  const { selectProps } = useSelect({
    resource: 'boats',
    optionLabel: 'name',
    optionValue: 'id',
  });

  return (
    <Form
      {...props}
      onFinish={data => {
        const preparedData = {
          ...data,
          special_price: Number(data?.special_price),
          start: data?.action_date[0].toISOString(),
          end: data?.action_date[1].toISOString(),
        };
        delete preparedData['action_date'];
        onFinish(preparedData);
      }}
    >
      <Form.Item
        label="Лодка"
        name="boat_id"
        rules={[
          {
            required: true,
            message: 'Обязательное поле',
          },
        ]}
      >
        <Select {...selectProps} />
      </Form.Item>
      <Form.Item
        label="Акционная цена"
        name="special_price"
        rules={[
          {
            required: true,
            message: 'Обязательное поле',
          },
        ]}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        label="Период акции"
        name="action_date"
        initialValue={[dayjs(props.initialValues?.start), dayjs(props.initialValues?.end)]}
        rules={[
          {
            required: true,
            message: 'Обязательное поле',
          },
        ]}
      >
        <RangePicker format={dateFormat} />
      </Form.Item>
    </Form>
  );
};

export default ActionForm;
