import { message } from 'antd';

export const handleTopicChange = async (id: string | undefined, option: any, mutate: any, refetch: () => void) => {
  if (id) {
    try {
      await mutate({
        resource: 'support/ticket',
        id,
        values: {
          support_topic_id: option,
        },
        successNotification: (data: any, values: any, resource: any) => {
          return {
            message: ``,
            description: 'Тикет успешно изменен',
            type: 'success',
          };
        },
      });
    } catch (error) {
      message.error('Ошибка при изменении тикета');
    }
  }
};
