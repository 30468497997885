import { Create, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import TopicForm from 'forms/TopicForm';
import { type FC } from 'react';

const TopicsCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm({
    resource: 'support/topic',
  });

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Создание темы"
    >
      <TopicForm {...formProps} />
    </Create>
  );
};

export default TopicsCreate;
