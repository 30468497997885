import { Edit, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import { Form, Radio } from 'antd';
import { PierI } from 'model/pier/interface';
import { type FC } from 'react';

const PierEdit: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading, queryResult } = useForm<PierI>();

  const pierName = queryResult?.data?.data?.name;

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={`Редактирование причала - ${pierName}`}
    >
      <Form {...formProps}>
        <Form.Item
          label="Активность"
          name="is_active"
        >
          <Radio.Group>
            <Radio value={true}>Активно</Radio>
            <Radio value={false}>Неактивно</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Edit>
  );
};

export default PierEdit;
