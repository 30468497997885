import { Create, useForm } from '@refinedev/antd';
import { IResourceComponentsProps } from '@refinedev/core';
import CapitanForm from 'forms/CapitanForm';

const CapitanCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Создание капитана"
    >
      <CapitanForm {...formProps} />
    </Create>
  );
};

export default CapitanCreate;
