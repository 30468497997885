import { Create, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import AdminForm from 'forms/AdminForm';
import { type FC } from 'react';

const AdminCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create
      title="Создание администратора"
      saveButtonProps={saveButtonProps}
    >
      <AdminForm
        isCreate={true}
        {...formProps}
      />
    </Create>
  );
};

export default AdminCreate;
