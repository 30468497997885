import { PlusOutlined } from '@ant-design/icons';
import { Create, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import { Form, Input, InputNumber, Select, Upload, message } from 'antd';
import { axiosInstance } from 'api/axiosInstance';
import { type ReportI } from 'model/report/interface';
import { type FC, useState } from 'react';

import { fields } from './fields';
import { FilterType, filtersConfigType, renderFilterType } from './types';

const fieldsForSelect = fields.map(field => ({ value: field.value, label: field.label }));

const PushCreate: FC<IResourceComponentsProps> = () => {
  const [filter, setFilter] = useState<FilterType>('all');

  const { formProps, saveButtonProps, form } = useForm<ReportI>();

  const filtersConfig: filtersConfigType = {
    birthday: {
      label: 'Количество дней',
      name: 'days_count',
      initialValue: 7,
      component: <InputNumber min={1} />,
    },
    name: {
      label: 'Введите имя',
      name: 'name',
      component: <Input />,
    },
    last_visit: {
      label: 'Количество дней с последнего визита',
      name: 'last_visit_days',
      initialValue: 7,
      component: <InputNumber min={1} />,
    },
  };

  const selectedFilter = filtersConfig[filter as renderFilterType];

  const onFinishHandler = async (values: any) => {
    let options = fields
      .find(v => v.value === filter)
      ?.options.reduce((acc, val) => ({ ...acc, [val]: values[val] }), {});
    let filterData = {
      type: filter,
      options,
    };

    const newData = {
      title: values.title,
      body: values.body,
      image_uuid: values.image_uuid,
      filter: filterData,
    };
    try {
      const res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/admin/push`, newData);
      if(res.data.users_count === 0) {
        message.error(`Пользователей по фильтру не найдено`);
      } else {
        message.success(`Пуш отправлен ${res.data.users_count} пользователям`);
      }
    } catch {
      message.error(`Ошибка при отправлении пуш уведомления`);
    }
    setFilter('all');
    form.resetFields();
  };

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Конструктор пушей"
    >
      <Form
        {...formProps}
        onFinish={onFinishHandler}
      >
        <Form.Item
          label="Название"
          name="title"
          rules={[
            {
              required: true,
              message: 'Это обязательное поле',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Текст пуша"
          name="body"
          rules={[
            {
              required: true,
              message: 'Это обязательное поле',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="image_uuid"
          hidden
        >
          <Input hidden />
        </Form.Item>

        <Form.Item
          label="Картинка"
          name="image"
        >
          <Upload
            action={`${process.env.REACT_APP_API_URL}/file`}
            listType="picture-card"
            multiple={true}
            name="files"
            onChange={e => {
              const { status } = e.file;
              if (status === 'done') {
                message.success('Файл загружен');
                form.setFieldValue('image_uuid', e.file.response[0]);
              }
            }}
            maxCount={1}
          >
            <button
              style={{ border: 0, background: 'none' }}
              type="button"
            >
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить</div>
            </button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Группы пользователей"
          name="type"
          initialValue={'all'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            onChange={(e: any) => setFilter(e)}
            options={fieldsForSelect}
          />
        </Form.Item>

        {/* Компоненты условного рендеринга в зависимости от выбранного фильтра */}
        <Form.Item
          label={selectedFilter?.label}
          name={selectedFilter?.name}
          initialValue={selectedFilter?.initialValue}
        >
          {selectedFilter?.component}
        </Form.Item>
      </Form>
    </Create>
  );
};

export default PushCreate;
