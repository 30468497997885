import { useEffect, useState } from 'react';

import { permissionsParser } from './permissionsParser';

export const useContentPermissions = () => {
  const [state, setState] = useState(permissionsParser(localStorage.getItem('permissions')));

  useEffect(() => {
    setState(permissionsParser(localStorage.getItem('permissions')));
  }, []);

  return {
    permissions: state,
  };
};
