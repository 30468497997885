import { BooleanField, DeleteButton, EditButton, List, ShowButton, TextField, useTable } from '@refinedev/antd';
import { type BaseRecord, type IResourceComponentsProps } from '@refinedev/core';
import { Space, Table } from 'antd';
import { type RouteI } from 'model/route/interface';
import { type FC } from 'react';

const RoutesList: FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<RouteI>();

  return (
    <List canCreate={false}>
      <Table {...tableProps}>
        <Table.Column
          dataIndex="is_active"
          title="Активность"
          sorter
          render={(_, record: RouteI) => <BooleanField value={record?.is_active} />}
        />
        <Table.Column
          dataIndex="name"
          title="Название"
          sorter
        />
        <Table.Column
          dataIndex="price"
          title="Цена"
          sorter
        />
        <Table.Column
          title="Действия"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default RoutesList;
