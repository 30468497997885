import { BooleanField, Show, TextField } from '@refinedev/antd';
import { type IResourceComponentsProps, useShow } from '@refinedev/core';
import { Typography } from 'antd';
import { type FC } from 'react';

const { Title } = Typography;

const PierShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  return (
    <Show
      isLoading={isLoading}
      title="Показать причал"
    >
      <Title level={5}>Название</Title>
      <TextField value={data?.data.name} />

      <Title level={5}>Максимальный уровень воды</Title>
      <TextField value={data?.data.maximum_water_level} />

      <Title level={5}>Широта</Title>
      <TextField value={data?.data?.coordinates?.latitude} />

      <Title level={5}>Долгота</Title>
      <TextField value={data?.data?.coordinates?.longitude} />

      <Title level={5}>Активность</Title>
      <BooleanField value={data?.data.is_active} />
    </Show>
  );
};

export default PierShow;
