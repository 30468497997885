import { Create, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import ServiceForm from 'forms/ServiceForm';
import { type FC } from 'react';

const ServiceCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Создание услуги"
    >
      <ServiceForm {...formProps} />
    </Create>
  );
};

export default ServiceCreate;
