import { DataProvider } from '@refinedev/core';
import { generateFilter } from '@refinedev/simple-rest';
import { axiosInstance } from 'api/axiosInstance';
import { AxiosInstance } from 'axios';
import { stringify } from 'query-string';

import { generateSort } from './helpers';

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance,
): Omit<Required<DataProvider>, 'createMany' | 'updateMany' | 'deleteMany'> => ({
  getList: async ({ resource, pagination, filters, sorters, meta }) => {
    const url = `${apiUrl}/${resource}/list`;

    const { current = 1, pageSize = 10, mode = 'server' } = pagination ?? {};

    const queryFilters = generateFilter(filters);

    const query: {
      start?: number;
      end?: number;
      sort?: string;
      order?: string;
      take?: number;
      page?: number;
    } = {};

    if (mode === 'server') {
      query.start = (current - 1) * pageSize;
      query.end = current * pageSize;
      query.take = pageSize;
      query.page = current;
    }

    if (mode === 'off') {
      query.take = 999;
    }

    const generatedSort = generateSort(sorters);
    if (generatedSort) {
      query.sort = JSON.stringify(generatedSort);
    }
    const { data, headers } = await httpClient.get(`${url}?${stringify(query)}&${stringify(queryFilters)}`);

    const total = data?.total;

    return {
      data: data.data,
      total: total || data?.data?.length,
    };
  },

  getMany: async ({ resource, ids }) => {
    const { data } = await httpClient.get(`${apiUrl}/${resource}?${stringify({ id: ids })}`);

    return {
      data,
    };
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`;

    const { data } = await httpClient.post(url, variables);

    return {
      data,
    };
  },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.patch(url, variables);

    return {
      data,
    };
  },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.get(url);

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.delete(url, {
      data: variables,
    });

    return {
      data,
    };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({ url, method, filters, sorters, payload, query, headers }) => {
    let requestUrl = `${url}?`;

    if (sorters) {
      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const sortQuery = generatedSort;

        // const sortQuery = {
        //     _sort: _sort.join(","),
        //     _order: _order.join(","),
        // };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
      case 'put':
      case 'post':
      case 'patch':
        axiosResponse = await httpClient[method](url, payload);
        break;
      case 'delete':
        axiosResponse = await httpClient.delete(url, {
          data: payload,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
