import { Edit, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import BoatOwnerForm from 'forms/BoatOwnerForm';
import { type FC } from 'react';

const BoatOwnerEdit: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm();

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title="Редактирование собственника"
    >
      <BoatOwnerForm {...formProps} />
    </Edit>
  );
};

export default BoatOwnerEdit;
