import { EditButton, List, ShowButton, useTable } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import { Space, Table } from 'antd';
import { type BoatI } from 'model/boat/interface';
import { type FC } from 'react';

const BoatsList: FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable();

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="id"
      >
        <Table.Column
          dataIndex="name"
          title="Название"
        />
        <Table.Column
          dataIndex="brand"
          title="Марка"
        />
        <Table.Column
          dataIndex="model"
          title="Модель"
        />
        <Table.Column
          dataIndex="reg_name"
          title="Номер судна"
        />
        <Table.Column<BoatI>
          dataIndex="actions"
          title="Действия"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default BoatsList;
