import { DeleteButton, EditButton, List, ShowButton, useTable } from '@refinedev/antd';
import { BaseRecord, IResourceComponentsProps } from '@refinedev/core';
import { Space, Table } from 'antd';
import { type CaptainI } from 'model/captain/interface';
import { type FC } from 'react';

const CapitansList: FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<CaptainI>();

  return (
    <List>
      <Table {...tableProps}>
        <Table.Column
          dataIndex="lastname"
          title="Фамилия"
          sorter
        />
        <Table.Column
          dataIndex="firstname"
          title="Имя"
          sorter
        />
        <Table.Column
          dataIndex="midname"
          title="Отчество"
          sorter
        />
        <Table.Column
          title="Действия"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default CapitansList;
