import { SmileOutlined, UploadOutlined } from '@ant-design/icons';
import { Show, useSelect } from '@refinedev/antd';
import { useOne, useUpdate } from '@refinedev/core';
import { Button, Input, List, Select, Space, Typography, Upload, message } from 'antd';
import TicketStatus from 'components/tickets/TicketStatus';
import { chatRenderItem } from 'components/tickets/chatRenderItem';
import { OneTicketI, SupportMessageI } from 'model/ticket/interface';
import { TopicI } from 'model/topics/interface';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import { closeTicketHandler } from 'utils/tickets/closeTicketHandler';
import { handleTopicChange } from 'utils/tickets/handleTopicChange';
import { sendMessageHandler } from 'utils/tickets/sendMessageHandler';

const { Title } = Typography;

const TicketShow = () => {
  const { id } = useParams();
  const { mutate } = useUpdate();
  const [inputMessage, setInputMessage] = useState('');
  const [imageUUID, setImageUUID] = useState('');
  const divRef = useRef<HTMLDivElement | null>(null);

  const socket = io(`/admin_support`, {
    reconnectionAttempts: 5,
    autoConnect: false,
    extraHeaders: {
      Authorization: `Bearer ${localStorage.getItem('refine-auth')}`,
    },
  });

  const { data, isLoading, refetch } = useOne<OneTicketI>({
    resource: 'support/ticket',
    id,
  });

  const [messages, setMessages] = useState(data?.data.SupportMessages || []);

  const user_id = useMemo(() => data?.data.user_id, [data]);

  const { data: userData } = useOne({
    resource: 'users',
    id: user_id,
  });

  const userName = useMemo(() => `${userData?.data?.firstname} ${userData?.data?.lastname}`, [userData]) || 'User';

  const startOption = useMemo(
    () => ({
      value: data?.data.SupportTopic.id.toString()!,
      label: data?.data.SupportTopic.name!,
    }),
    [data],
  );

  const { selectProps } = useSelect<TopicI>({
    resource: 'support/topic',
    optionLabel: 'name',
    optionValue: 'id',
  });

  useEffect(() => {
    setMessages(data?.data.SupportMessages || []);
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      if (divRef.current) {
        divRef.current.scrollTop = divRef.current.scrollHeight;
      }
    }, 500);
  }, [messages]);

  useEffect(() => {
    socket.connect();
    console.log('Подключено');

    socket.on('new_support_message', (message: SupportMessageI) => {
      console.log('Received new support message:', message);
      // refetch();
      setMessages(prevMessages => [...prevMessages, message]);
    });

    return () => {
      socket.disconnect();
      socket.off('new_support_message');
    };
  }, []);

  if (isLoading) {
    return <p>Загрузка...</p>;
  }

  const isDisabled = !data?.data.active;

  return (
    <Show
      headerButtons={
        <Button
          onClick={() => closeTicketHandler(id, refetch)}
          danger
          disabled={isDisabled}
          type="primary"
        >
          Закрыть обращение
        </Button>
      }
    >
      <Space
        direction="vertical"
        size="large"
        style={{
          width: '100%',
        }}
      >
        <TicketStatus active={data?.data.active || false} />
        <div>
          <Title level={5}>Проблема</Title>
          <Select
            disabled={isDisabled}
            onChange={option => handleTopicChange(id, option, mutate, refetch)}
            style={{ width: '325px' }}
            defaultValue={startOption}
            {...selectProps}
          />
        </div>
        <div>
          <Title level={5}>Чат с пользователем</Title>
          <div
            style={{ maxHeight: '400px', overflowY: 'scroll', marginBottom: '16px' }}
            ref={divRef}
          >
            <List
              itemLayout="horizontal"
              dataSource={messages}
              renderItem={(item: SupportMessageI) => chatRenderItem(item, userName)}
            />
          </div>

          {!isDisabled && (
            <Input
              name="input"
              value={inputMessage}
              onChange={e => setInputMessage(e.target.value)}
              onPressEnter={() =>
                sendMessageHandler(id, inputMessage, imageUUID, setInputMessage, setImageUUID, setMessages)
              }
              suffix={
                <>
                  <Upload
                    action={`${process.env.REACT_APP_API_URL}/file`}
                    showUploadList={imageUUID ? true : false}
                    multiple={true}
                    name="files"
                    maxCount={1}
                    onRemove={() => {
                      setImageUUID('');
                    }}
                    onChange={e => {
                      const { status } = e.file;
                      if (status === 'done') {
                        message.success('Файл загружен');
                        setImageUUID(e.file.response[0]);
                      }
                    }}
                  >
                    {imageUUID ? null : <Button icon={<UploadOutlined />}></Button>}
                  </Upload>
                  <Button
                    onClick={() =>
                      sendMessageHandler(id, inputMessage, imageUUID, setInputMessage, setImageUUID, setMessages)
                    }
                  >
                    Отправить
                  </Button>
                </>
              }
              prefix={<SmileOutlined />}
            />
          )}
        </div>
      </Space>
    </Show>
  );
};

export default TicketShow;
