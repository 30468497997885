//@ts-nocheck
import { List, TextField, useEditableTable } from '@refinedev/antd';
import { IResourceComponentsProps, useUpdate } from '@refinedev/core';
import { Checkbox, Form, Input, Table } from 'antd';
import { TopicI } from 'model/topics/interface';
import React, { FC, useState } from 'react';

const TopicsList: FC<IResourceComponentsProps> = () => {
  const { tableProps, formProps } = useEditableTable<TopicI>({
    resource: 'support/topic',
  });

  const { mutate } = useUpdate();

  const [editingId, setEditingId] = useState(null);

  const successNotification = (data, values, resource) => ({
    description: 'Тема успешно изменена',
    type: 'success',
  });

  const handleTextFieldClick = (id: any) => setEditingId(id);

  const handleInputBlur = (value: string, record: TopicI) => {
    setEditingId(null);
    mutate({
      resource: 'support/topic',
      id: record.id,
      values: { name: value },
      successNotification,
    });
  };

  const handleEnabledChange = (checked: boolean, record: TopicI) => {
    mutate({
      resource: 'support/topic',
      id: record.id,
      values: { enabled: checked },
      successNotification,
    });
  };

  return (
    <List>
      <Form {...formProps}>
        <Table
          {...tableProps}
          rowKey="id"
        >
          <Table.Column
            dataIndex="name"
            title="Название"
            sorter
            render={(value, record: TopicI) =>
              editingId === record.id ? (
                <Input
                  defaultValue={value}
                  onBlur={e => handleInputBlur(e.target.value, record)}
                  onPressEnter={e => handleInputBlur(e.target.value!, record)}
                />
              ) : (
                <TextField
                  value={value}
                  onClick={() => handleTextFieldClick(record.id)}
                />
              )
            }
          />
          <Table.Column
            title="Показывать?"
            dataIndex="enabled"
            width="20%"
            render={(_, record: TopicI) => (
              <Form.Item
                name="enabled"
                style={{ margin: 0 }}
              >
                <Checkbox
                  checked={record.enabled}
                  onChange={e => handleEnabledChange(e.target.checked, record)}
                />
              </Form.Item>
            )}
          />
        </Table>
      </Form>
    </List>
  );
};

export default TopicsList;
