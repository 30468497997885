import { SmileOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Image, List } from 'antd';
import { SupportMessageI } from 'model/ticket/interface';

export const chatRenderItem = (item: SupportMessageI, userName: string) => {
  return (
    <List.Item>
      <List.Item.Meta
        avatar={<Avatar icon={item.user_id ? <UserOutlined /> : <SmileOutlined />} />}
        title={<span style={{ fontWeight: 'bold' }}>{item.user_id ? userName : 'Admin'}</span>}
        description={
          item.type === 'TEXT' ? (
            <div style={{ display: 'flex', alignItems: 'center', paddingRight: '16px' }}>
              <p style={{ margin: 0 }}>{item.content}</p>
            </div>
          ) : (
            <Image
              src={`${process.env.REACT_APP_API_URL}/file/${item.files[0].id}`}
              height={150}
            />
          )
        }
      />
    </List.Item>
  );
};
