import { useApiUrl, useBack, useCustomMutation, useTranslate } from '@refinedev/core';
import { Button, type ButtonProps, Popconfirm } from 'antd';
import { type FC } from 'react';

interface CancelButtonP extends ButtonProps {
  popupTitle?: string;
  itemId?: number;
  onSuccess?: (data: any) => void;
  url?: string;
  resource: string;
}

export const CancelButton: FC<CancelButtonP> = ({
  children = 'Отменить',
  popupTitle = 'Вы уверены?',
  resource,
  url,
  itemId,
  onSuccess,
  disabled,
  ...props
}) => {
  const apiUrl = useApiUrl();

  const back = useBack();

  const translate = useTranslate();

  const { mutate, isLoading } = useCustomMutation();

  return (
    <Popconfirm
      key="cancel"
      title={popupTitle}
      okText={children}
      okType="danger"
      cancelText={translate('buttons.cancel', 'Cancel')}
      okButtonProps={{ disabled: isLoading }}
      onConfirm={(): void => {
        if (itemId) {
          mutate(
            {
              url: url || `${apiUrl}/${resource}/${itemId}/cancel`,
              //@ts-ignore
              method: 'get',
            },
            {
              onSuccess: data => {
                console.log(data);
                back();
              },
            },
          );
        }
      }}
      // disabled
      // disabled={
      //     typeof rest?.disabled !== "undefined"
      //         ? rest.disabled
      //         : data?.can === false
      // }
    >
      <Button
        // disabled={disabled}
        danger
        {...props}
      >
        {children}
      </Button>
    </Popconfirm>
  );
};

//? Добавить i18 при необходимости
