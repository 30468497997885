import { Create, useForm } from '@refinedev/antd';
import ActionForm from 'forms/ActionForm';
import { ActionI } from 'model/actions/interface';


const ActionCreate = () => {
  const form = useForm<ActionI>();

  const { formProps, saveButtonProps, formLoading, queryResult, onFinish } = form;

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Создание акции"
    >
      <ActionForm
        
        {...formProps}
        onFinish={onFinish}
        addBoat={true}
      />
    </Create>
  );
};
export default ActionCreate;