import { Show } from '@refinedev/antd';
import { IResourceComponentsProps } from '@refinedev/core';
import { useShow } from '@refinedev/core';
import { Carousel, Image, Typography } from 'antd';
import { Col, Row } from 'antd';
import { Image as CustomImage } from 'components';
import { BoatI } from 'model/boat/interface';
import { type FC } from 'react';

const { Title, Text } = Typography;

const BoatsShow: FC<IResourceComponentsProps> = () => {
  const {
    queryResult: { data, isLoading },
  } = useShow<BoatI>();

  const boat = data?.data;

  return (
    <Show
      title="Просмотр лодки"
      isLoading={isLoading}
      headerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          {/* <Button>Снять с линии</Button>
                    <Button>История</Button> */}
        </>
      )}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Title level={5}>Название</Title>
          <Text>{boat?.name}</Text>

          <Title level={5}>Марка</Title>
          <Text>{boat?.brand}</Text>

          <Title level={5}>Модель</Title>
          <Text>{boat?.model}</Text>

          <Title level={5}>Номер судна</Title>
          <Text>{boat?.reg_name}</Text>
        </Col>
        <Col span={12}>
          <Title level={5}>Номер телефона</Title>
          <Text>{boat?.owner?.phone}</Text>

          <Title level={5}>Собственник</Title>
          <Text>{`${boat?.owner?.firstname} ${boat?.owner?.lastname}`}</Text>

          <Title level={5}>Категория</Title>
          <Text>{boat?.category?.name}</Text>

          <Title level={5}>Капитан</Title>
          <Text>{`${boat?.captain?.firstname} ${boat?.captain?.lastname}`}</Text>
        </Col>
      </Row>

      <Row
        style={{ marginBlock: '1rem' }}
        gutter={16}>
        <Col span={12}>
          <Title
            level={5}
          >
            Текстовое описания лодки
          </Title>
          <Text>{boat?.description}</Text>
        </Col>
        <Col>
          <Title level={5}>ID Google календаря лодки</Title>
          <Text>{boat?.google_cal_id}</Text>
        </Col>
      </Row>

      <Title level={5}>Основное фото</Title>
      <CustomImage
        height="800px"
        fileId={boat?.main_image?.[0]?.id}
      />

      <Title level={5}>Фото/Видео</Title>
      <Carousel>
        {boat?.images?.map(image => (
          // <Image height="400px" width="100%" src={`${process.env.REACT_APP_API_URL}/file/${image.id}`} />
          <CustomImage
            height="400px"
            width="100%"
            fileId={image.id}
          />
        ))}
      </Carousel>
    </Show>
  );
};

export default BoatsShow;
