import { Edit, useForm } from '@refinedev/antd';
import { IResourceComponentsProps } from '@refinedev/core';
import CapitanForm from 'forms/CapitanForm';

const CapitanEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm();

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title="Редактирование капитана"
    >
      <CapitanForm {...formProps} />
    </Edit>
  );
};

export default CapitanEdit;

// Вывод Фамилии Имени
// Вывод Дата рождения
// Вывод Номер телефона
// Вывод Адрес электронной почты
// Кнопка ""Редактировать данные капитана "".По клику - отображение страницы ""Редактирование данных капитана"".
// Кнопка ""Удалить капитана"" . По клику - администратору дается 5 секунд для отмены действия, если действие не отменено то капитан удаляется" 4,00
