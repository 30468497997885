export const ticketTag = {
  open: {
    color: 'green',
    text: 'Открыто',
  },
  closed: {
    color: 'red',
    text: 'Закрыто',
  },
};
