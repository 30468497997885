//@ts-nocheck
import { Edit, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import { Form, Input, InputNumber, Radio } from 'antd';
import { UploadForm } from 'components';
import { type RouteI } from 'model/route/interface';
import { type FC } from 'react';

const RouteEdit: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading, queryResult, onFinish } = useForm<RouteI>();

  const routeName = queryResult?.data?.data?.name;

  const files: any = queryResult?.data?.data?.images;
  const mainImage: any = queryResult?.data?.data?.main_image;

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title={`Редактирование маршрута - ${routeName}`}
    >
      <Form
        {...formProps}
        onFinish={data => {
          console.log(data);
          const preparedData = {
            ...data,
            images: data?.images?.fileList?.map((el: any) => {
              if (el.id) {
                return el.id;
              }
              return el.response[0];
            }),
            main_image: data?.main_image?.fileList?.map((el: any) => el.response[0]),
          };
          onFinish(preparedData);
        }}
      >
        <Form.Item
          label="Активность"
          name="is_active"
        >
          <Radio.Group>
            <Radio value={true}>Активно</Radio>
            <Radio value={false}>Неактивно</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Цена"
          name="price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item
          label="Описание"
          name="description"
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Изображения"
          name="images"
        >
          <UploadForm defaultFileList={files} />
        </Form.Item>
        <Form.Item
          label="Основное изображение"
          name="main_image"
        >
          <UploadForm
            maxCount={1}
            defaultFileList={mainImage}
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};

export default RouteEdit;
