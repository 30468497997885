export const fields = [
  {
    label: 'Всем',
    value: 'all',
    options: [],
  },
  {
    label: 'Дней до дня рождения',
    value: 'birthday',
    options: ['days_count'],
  },
  {
    label: 'По имени',
    value: 'name',
    options: ['name'],
  },
  {
    label: 'Кто ничего не бронировал',
    value: 'not_booking',
    options: [],
  },
  {
    label: 'Дней с последнего визита',
    value: 'last_visit',
    options: ['last_visit_days'],
  },
];
