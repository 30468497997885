import { Form, Input } from 'antd';
import { type FC } from 'react';

const TopicForm: FC<any> = ({ ...props }) => {
  return (
    <Form {...props}>
      <Form.Item
        label="Имя"
        name="name"
        rules={[
          {
            required: true,
            message: 'Обязательное поле',
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default TopicForm;
