import { emailMessage, passwordMessage, phoneMessage } from './messages';
import { email, password, phone } from './patterns';

interface ValidationObjectI {
  pattern: RegExp;
  message: string;
}

export const emailValidation: ValidationObjectI = {
  pattern: email,
  message: emailMessage,
};

export const phoneValidation: ValidationObjectI = {
  pattern: phone,
  message: phoneMessage,
};

export const passwordValidation: ValidationObjectI = {
  pattern: password,
  message: passwordMessage,
};
