import { DeleteButton, EditButton, List, TextField, useTable } from '@refinedev/antd';
import { BaseRecord } from '@refinedev/core';
import { Space, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import { ActionI } from 'model/actions/interface';
import React from 'react';
import { formatter } from 'utils/formatter';

const ActionsList: React.FC = () => {
  const { tableProps } = useTable<ActionI>();

  console.log(tableProps);
  return (
    <List>
      <Table {...tableProps}>
        <Table.Column
          dataIndex="boat_id"
          title="ID лодки"
          render={(_, record: BaseRecord) => <TextField value={record?.boats[0]?.id} />}
        />
        <Table.Column
          dataIndex="boat_name"
          title="Название лодки"
          render={(_, record: BaseRecord) => <TextField value={record?.boats[0]?.name} />}
        />
        <Table.Column
          dataIndex="boat_category"
          title="Категория лодки"
          render={(_, record: BaseRecord) => <TextField value={record?.boats[0]?.category?.name} />}
        />
        <Table.Column
          dataIndex="old_price"
          title="Старая цена"
          render={(_, record: BaseRecord) => <TextField value={formatter.format(record?.boats[0]?.price_per_hour)} />}
        />
        <Table.Column
          dataIndex="promotion_price"
          title="Новая цена"
          render={(_, record: BaseRecord) => <TextField value={formatter.format(record?.special_price)} />}
        />
        <Table.Column
          dataIndex="duration"
          title="Сроки акции"
          render={(_, record: BaseRecord) => (
            <div style={{ display: 'flex' }}>
              <Tag>{dayjs(record.start).format('DD-MM-YYYY')}</Tag>
              <Tag>{dayjs(record.end).format('DD-MM-YYYY')}</Tag>
            </div>
          )}
        />

        <Table.Column
          title="Действия"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default ActionsList;
