import { axiosInstance } from 'api/axiosInstance';

export async function closeTicket(ticketId: string) {
  try {
    const response = await axiosInstance.patch(
      `${process.env.REACT_APP_API_URL}/admin/support/ticket/${ticketId}/close`,
    );
    return response.data;
  } catch (error: any) {
    throw new Error(`Ошибка при закрытии тикета: ${error.message || 'Ошибка удаления'}`);
  }
}
