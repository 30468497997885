import { type BookingStatusT } from 'model/booking/interface';

interface StatusI<T extends string | number | symbol> {
  values: Record<T, string>;
  getOne: (key: T) => string;
}

export const bookingPayStatus: StatusI<BookingStatusT> = {
  values: {
    TEMP: 'Временный',
    ACTIVE: 'Активен',
    CANCELED: 'Отменен',
    PAID: 'Оплачен',
    PREPAID: 'Предоплата',
    PREPAID_TEMP: 'Временный',
  },
  getOne(key) {
    return this.values[key];
  },
};
