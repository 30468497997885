import { Edit, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import { useWatch } from 'antd/es/form/Form';
import BoatForm from 'forms/BoatForm';
import { type BoatI } from 'model/boat/interface';
import { type FC } from 'react';

const BoatsEdit: FC<IResourceComponentsProps> = () => {
  const form = useForm<BoatI>();

  const isPreviewValueAdded = useWatch('video_preview', form);
  const isVideoValueAdded = useWatch('video', form);

  const isVideoFieldsValidating = isPreviewValueAdded?.fileList?.length || isVideoValueAdded?.fileList?.length;

  const { formProps, saveButtonProps, queryResult, formLoading, onFinish } = form;

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title="Редактирование лодки"
    >
      <BoatForm
        files={queryResult?.data?.data.images}
        mainImage={queryResult?.data?.data.main_image}
        videos={queryResult?.data?.data?.videos}
        videos_preview={queryResult?.data?.data?.videos?.[0]?.preview_image}
        onFinish={onFinish}
        isVideoFieldsValidating={isVideoFieldsValidating}
        {...formProps}
      />
    </Edit>
  );
};

export default BoatsEdit;
