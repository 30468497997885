import { Create, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps, useSelect } from '@refinedev/core';
import { DatePicker, Form, Input, InputNumber, Select } from 'antd';
import { type FC, useState } from 'react';
import { RoutesE } from 'utils/const/routes';

const BookingCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  const [isFreeRoute, setIsFreeRoute] = useState<boolean>(false);

  const boatOptions = useSelect({
    resource: 'boats',
    optionLabel: 'name',
    optionValue: 'id',
  });

  const routeOptions = useSelect({
    resource: 'routes',
    optionLabel: 'name',
    optionValue: 'id',
  });

  const userOptions = useSelect({
    resource: 'users',
    optionLabel: 'firstname',
    optionValue: 'id',
  });

  const pierOptions = useSelect({
    resource: 'piers',
    optionLabel: 'name',
    optionValue: 'id',
  });

  const handleSelectRouteId = (routeId: number) => {
    formProps.form?.setFieldValue('routeId', routeId);

    //@ts-ignore
    const isFree = routeId === routeOptions?.queryResult?.data?.data.find(route => route?.type === RoutesE.FREE).id;

    setIsFreeRoute(isFree);
  };

  return (
    <Create
      title="Инициировать поездку"
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps}>
        <Form.Item
          label="Пользователь"
          name="user_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...userOptions} />
        </Form.Item>
        <Form.Item
          label="Время начала"
          name="start_time"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker showTime />
        </Form.Item>
        <Form.Item
          label="Время окончания"
          name="end_time"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker showTime />
        </Form.Item>
        <Form.Item
          label="Причал посадки"
          name="start_pier_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...pierOptions} />
        </Form.Item>
        <Form.Item
          label="Причал высадки"
          name="end_pier_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...pierOptions} />
        </Form.Item>
        <Form.Item
          label="Лодка"
          name="boat_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...boatOptions} />
        </Form.Item>
        <Form.Item
          label="Маршрут"
          name="route_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            onChange={handleSelectRouteId}
            {...routeOptions}
          />
        </Form.Item>
        {isFreeRoute && (
          <Form.Item
            label="Длительность (сек.)"
            name="duration_seconds"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber controls={false} />
          </Form.Item>
        )}
        <Form.Item
          label="Пометки"
          name="comment"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};

export default BookingCreate;
