import { Create, useForm } from '@refinedev/antd';
import { type HttpError, type IResourceComponentsProps, useList } from '@refinedev/core';
import { Form, Input, Select } from 'antd';
import { type ReportI } from 'model/report/interface';
import { type FC } from 'react';

const fieldsMock = [
  {
    label: 'Лодка',
    value: 'BOAT_NAME',
  },
  {
    label: 'Регистрационный номер',
    value: 'BOAT_REG_NUMBER',
  },
  {
    label: 'Капитан',
    value: 'CAPTAIN',
  },
  {
    label: 'Сумма',
    value: 'SUM',
  },
];

const ReportCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ReportI>();

  const { data: filtersData, isLoading } = useList<any, HttpError>({
    resource: 'reports/filters',
  });

  const filters = filtersData?.data?.map((filter: any) => ({ value: filter.name, label: filter.value }));

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Создать шаблон отчета"
    >
      <Form {...formProps}>
        <Form.Item
          label="Название"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Поля"
          name="fields"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={fieldsMock}
            mode="multiple"
          />
        </Form.Item>
        <Form.Item
          label="Фильтры"
          name="filters"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={filters}
            mode="multiple"
          />
        </Form.Item>
      </Form>
    </Create>
  );
};

export default ReportCreate;
