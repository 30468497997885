import { Map, Placemark, Polyline } from '@pbe/react-yandex-maps';
import { BooleanField, Show, TextField } from '@refinedev/antd';
import { type IResourceComponentsProps, useShow } from '@refinedev/core';
import { Typography } from 'antd';
import { type FC } from 'react';

const { Title } = Typography;

const RouteShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const route = queryResult?.data?.data;

  return (
    <Show
      isLoading={queryResult.isLoading}
      title="Показать маршрут"
    >
      <Title level={5}>Название</Title>
      <TextField value={route?.name} />

      <Title level={5}>Цена</Title>
      <TextField value={route?.price} />

      <Title level={5}>Дистанция (в метрах)</Title>
      <TextField value={route?.distance_meters} />

      <Title level={5}>Активность</Title>
      <BooleanField value={route?.is_active} />

      <Title level={5}>Маршрут</Title>

      {!queryResult.isLoading && route && (
        <Map
          width="100%"
          height="600px"
          defaultState={{ center: Object.values(route?.piers?.[0]?.coordinates), zoom: 12 }}
        >
          {/* <Placemark geometry={Object.values(route?.start_pier?.coordinates)} />
                    <Placemark geometry={Object.values(route?.end_pier?.coordinates)} /> */}
          <Polyline
            geometry={route?.waypoints?.map((point: any) => [point.longitude, point.latitude])}
            options={{
              strokeColor: '#DC143C',
              strokeWidth: 4,
              strokeOpacity: 0.7,
            }}
          />
        </Map>
      )}
    </Show>
  );
};

export default RouteShow;
