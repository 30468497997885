import { AuthBindings } from '@refinedev/core';
import { ResponseLoginI } from 'model/authentication/interface';

import { axiosInstance } from './axiosInstance';

export const TOKEN_KEY = 'refine-auth';

export const authProvider: AuthBindings = {
  login: async data => {
    const { status, data: resData }: ResponseLoginI = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/admin/auth/login`,
      JSON.stringify(data),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (status) {
      localStorage.setItem(TOKEN_KEY, resData.access_token);
      localStorage.setItem('role', resData.role.name);

      localStorage.setItem('permissions', JSON.stringify(resData.role.permissions.data));

      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${resData.access_token}`,
      };
      window.location.reload(); // to read data from localstorage, is there a global storage here?
      return {
        success: true,
        redirectTo: '/',
      };
    } else {
      return {
        success: false,
        error: {
          name: 'Ошибка авторизации',
          message: 'Не верный логин или пароль',
        },
      };
    }
  },
  logout: async () => {
    localStorage.clear();
    return {
      success: true,
      redirectTo: '/login',
    };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: '/login',
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    // if (token) {
    //   return {
    //     id: 1,
    //     name: "John Doe",
    //     avatar: "https://i.pravatar.cc/300",
    //   };
    // }
    return null;
  },
  onError: async error => {
    console.error(error);
    return { error };
  },
};
