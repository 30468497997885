import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, UploadProps } from 'antd';
import { UploadFile } from 'antd/lib/upload';
import { type FC, useState } from 'react';

export const UploadForm: FC<UploadProps> = ({ defaultFileList, ...props }) => {
  const [preview, setPreview] = useState({
    isActive: false,
    title: '',
    image: '',
  });

  const onPreview = (file: UploadFile) => {
    setPreview(prev => ({
      ...prev,
      isActive: true,
      title: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
      image: file.url || `${process.env.REACT_APP_API_URL}/file/${file.response[0]}`,
    }));
  };

  const onClose = () => setPreview(prev => ({ ...prev, isActive: false }));

  return (
    <>
      <Upload
        action={`${process.env.REACT_APP_API_URL}/file`}
        listType="picture-card"
        name="files"
        onPreview={onPreview}
        //@ts-ignore
        defaultFileList={defaultFileList?.map((el: any) => ({
          ...el,
          url: `${process.env.REACT_APP_API_URL}/file/${el?.id}`,
        }))}
        {...props}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      </Upload>
      <Modal
        open={preview.isActive}
        title={preview.title}
        footer={null}
        onCancel={onClose}
      >
        <img
          alt="icon"
          style={{ width: '100%' }}
          src={preview.image}
        />
      </Modal>
    </>
  );
};
