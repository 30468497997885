import { Tag, Typography } from 'antd';
import { ticketTag } from 'components/ticketTag';
import React from 'react';

const { Title } = Typography;

interface TicketStatusProps {
  active: boolean;
}

const TicketStatus: React.FC<TicketStatusProps> = ({ active }) => {
  return (
    <div>
      <Title level={5}>Статус</Title>
      <Tag color={active ? ticketTag.open.color : ticketTag.closed.color}>
        {active ? ticketTag.open.text : ticketTag.closed.text}
      </Tag>
    </div>
  );
};

export default TicketStatus;
