import { DatePicker, Form, Input } from 'antd';
import dayjs from 'dayjs';
import { type FC } from 'react';
import { emailValidation, phoneValidation } from 'utils/validation/validation';

const ClientForm: FC = ({ ...props }) => {
  return (
    <Form {...props}>
      <Form.Item
        label="Имя"
        name="firstname"
        rules={[
          {
            required: true,
            message: 'Обязательное поле',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Фамилия"
        name="lastname"
        rules={[
          {
            required: true,
            message: 'Обязательное поле',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Дата рождения"
        name="birthday"
        getValueProps={value => ({
          value: value ? dayjs(value) : '',
        })}
        rules={[
          {
            required: true,
            message: 'Обязательное поле',
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="Номер телефона"
        name="phone"
        rules={[
          {
            required: true,
            ...phoneValidation,
          },
        ]}
      >
        <Input placeholder="79*********" />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            ...emailValidation,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default ClientForm;
