import { Show, TextField } from '@refinedev/antd';
import { type IResourceComponentsProps, useShow } from '@refinedev/core';
import { Button, DatePicker, Typography } from 'antd';
import { axiosInstance } from 'api/axiosInstance';
import { QueryField } from 'entity/report/components';
import { queryParse } from 'entity/report/helpers/queryParse';
import { type FC, useState } from 'react';

const { Title } = Typography;

const ReportShow: FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [capitan, setCapitan] = useState(0);
  const [boat, setBoat] = useState(0);

  const handleCreateFileClick = async () => {
    const query = queryParse({ startDate, endDate, capitan, boat });

    await axiosInstance
      .get(`${process.env.REACT_APP_API_URL}/admin/reports/${data?.data?.id}/getreport${query}`, {
        responseType: 'blob',
      })
      .then(response => {
        const href = URL.createObjectURL(response.data);
        const dt = new Date();
        const name = `${data?.data.name} от ${dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear()}.xlsx`;

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;

        link.setAttribute('download', name); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch(error => alert(error));
  };

  return (
    <Show
      isLoading={isLoading}
      title="Просмотр отчета"
      headerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          <Button onClick={handleCreateFileClick}>Сформировать</Button>
        </>
      )}
    >
      <Title level={5}>Название</Title>
      <TextField value={data?.data.name} />
      <QueryField
        filters={data?.data.filters}
        setCapitan={setCapitan}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setBoat={setBoat}
      />
    </Show>
  );
};

export default ReportShow;
