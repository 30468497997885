import { CrudSorting } from '@refinedev/core';

export const generateSort = (sorters?: CrudSorting) => {
  if (sorters && sorters.length > 0) {
    const customSorters = {};

    Object.values(sorters[0]).forEach((elem, index, self) => {
      if (index % 2) {
        //@ts-ignore
        customSorters[self[index - 1]] = elem;
      }
    });

    return customSorters;
  }

  return;
};
