import { Edit, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import CategoryForm from 'forms/CategoryForm';
import { ServiceI } from 'model/service/interface';
import { type FC } from 'react';

export const CategoryEdit: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading, queryResult } = useForm<ServiceI>();

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title="Редактирование категории"
    >
      <CategoryForm
        files={queryResult?.data?.data.icon}
        {...formProps}
      />
    </Edit>
  );
};
