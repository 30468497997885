// @ts-nocheck

export const permissionsParser = (permissions: any): [] => {
  const initialPermission = [
    {
      name: 'boats',
      meta: {
        canDelete: true,
        label: 'Лодки',
      },
    },
    {
      name: 'actions',
      meta: {
        canDelete: true,
        label: 'Акции',
      },
    },
    {
      name: 'boat-owner',
      meta: {
        canDelete: true,
        label: 'Собственник',
      },
    },
    {
      name: 'bookings',
      meta: {
        canDelete: true,
        label: 'Бронирование',
      },
    },
    {
      name: 'captains',
      meta: {
        canDelete: true,
        label: 'Капитаны',
      },
    },
    {
      name: 'users',
      meta: {
        canDelete: true,
        label: 'Клиенты',
        parent: 'Пользователи',
      },
    },
    {
      name: 'admins',
      meta: {
        canDelete: true,
        label: 'Администраторы',
        parent: 'Пользователи',
      },
    },
    {
      name: 'trip',
      meta: {
        label: 'Инициировать поездку',
      },
    },
    {
      name: 'piers',
      meta: {
        canDelete: true,
        label: 'Причалы',
      },
    },
    {
      name: 'routes',
      meta: {
        canDelete: true,
        label: 'Маршруты',
      },
    },
    {
      name: 'boat-categories',
      meta: {
        canDelete: true,
        label: 'Категории лодок',
      },
    },
    {
      name: 'services',
      meta: {
        canDelete: true,
        label: 'Услуги лодок',
      },
    },
    {
      name: 'topic',
      meta: {
        label: 'Темы',
        parent: 'Поддержка',
      },
    },
    {
      name: 'ticket',
      meta: {
        label: 'Обращения',
        parent: 'Поддержка',
      },
    },
    {
      name: 'reports',
      meta: {
        canDelete: true,
        label: 'Отчеты',
      },
    },
  ];

  if (!permissions) {
    return initialPermission as [];
  }

  permissions = JSON.parse(permissions);

  const helper = (endpoint: string) => {
    if (endpoint === 'list') {
      return '';
    }
    if (endpoint === 'edit' || endpoint === 'show') {
      return `/${endpoint}`.concat('/:id');
    }

    return `/${endpoint}`;
  };

  //@ts-ignore
  permissions.map(permission => {
    if (permission === 'push_create') return null;
    permission = permission.replace('support_', '');

    let [name, endpoint] = permission.split('_');

    const index = initialPermission.findIndex(elem => elem.name === name);
    if (index >= 0) {
      //@ts-ignore
      initialPermission[index][endpoint] = `/${name}${helper(endpoint)}`;
    } else {
      //@ts-ignore
      initialPermission.push({
        name,
        [endpoint]: `/${name}${helper(endpoint)}`,
      });
    }
  });

  initialPermission.push({
    name: 'push',
    //@ts-ignore
    list: '/push/create',
    meta: {
      canDelete: true,
      label: 'Конструктор пушей',
    },
  });

  return initialPermission as [];
};
