import { axiosInstance } from 'api/axiosInstance';

export interface photoMessageI {
  type: 'PHOTO';
  support_ticket_id: number;
  files: string[];
}
export interface textMessageI {
  type: 'TEXT';
  content: string;
  support_ticket_id: number;
}

export async function sendMessageText(message: textMessageI) {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/admin/support/message/send`, message);
    return response.data;
  } catch (error: any) {
    throw new Error(`Ошибка при отправке сообщения: ${error.message || 'Ошибка отправления'}`);
  }
}
export async function sendMessageImage(message: photoMessageI) {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/admin/support/message/send`, message);
    return response.data;
  } catch (error: any) {
    throw new Error(`Ошибка при отправке сообщения: ${error.message || 'Ошибка отправления'}`);
  }
}
