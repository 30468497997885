import { Create, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import BoatOwnerForm from 'forms/BoatOwnerForm';
import { type FC } from 'react';

const BoatOwnerCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Создание собственника"
    >
      <BoatOwnerForm {...formProps} />
    </Create>
  );
};

export default BoatOwnerCreate;
