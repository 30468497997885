import { Create, useForm } from '@refinedev/antd';
import { type IResourceComponentsProps } from '@refinedev/core';
import CategoryForm from 'forms/CategoryForm';
import { type FC } from 'react';

const CategoryCreate: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Создание категории лодок"
    >
      <CategoryForm {...formProps} />
    </Create>
  );
};

export default CategoryCreate;
