import { Edit, useForm } from '@refinedev/antd';
import { IResourceComponentsProps } from '@refinedev/core';
import ClientForm from 'forms/ClientForm';
import { type ClientI } from 'model/client/interface';
import { type FC } from 'react';

const ClientEdit: FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm<ClientI>();

  return (
    <Edit
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
      title="Редактирование клиента"
    >
      <ClientForm {...formProps} />
    </Edit>
  );
};

export default ClientEdit;
