import { BooleanField, DeleteButton, EditButton, ImageField, List, ShowButton, useTable } from '@refinedev/antd';
import { type BaseRecord, type IResourceComponentsProps } from '@refinedev/core';
import { Space, Table } from 'antd';
import { type ServiceI } from 'model/service/interface';
import { type FC } from 'react';

const ServicesList: FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<ServiceI>();

  return (
    <List>
      <Table {...tableProps}>
        <Table.Column
          dataIndex="is_active"
          title="Активность"
          sorter
          render={(_, record: ServiceI) => <BooleanField value={record?.is_active} />}
        />
        <Table.Column
          dataIndex="name"
          title="Название"
          sorter
        />
        <Table.Column
          title="Иконка"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <ImageField
              value={`${process.env.REACT_APP_API_URL}/file/${record?.icon?.[0]?.id}`}
              title="test"
              width="50px"
            />
          )}
        />
        <Table.Column
          title="Действия"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default ServicesList;
